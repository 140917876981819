import { CARVING_SETSTATE } from "./CarvingActionType";

const initialState = {
    LBL_FROMDATE: "From Date",
    LBL_TODATE: "To Date",
    LBL_SEARCH: 'Search',
    LBL_SCANNEDON: "Scanned On",
    LBL_DEPARTMENT: "Department",
    LBL_FOLDERNAME: "Folder Name",
    LBL_FILENAME: "File Name",
    LBL_FILEDATE: "File Date",
    LBL_FILESIZE: "File Size",
    LBL_PROCESSEDDATE: "Processed Date",
    LBL_NUMBEROFPAGE: "No. of Page",
    LBL_NUMBEROFSLPITTEDDOC: "No. of Splitted Document",
    LBL_COMPLETEDATE: "Completed On",
    LBL_STATUS: "Status",
    LBL_FROMDATE_VALIDATION: "Invalid From Date.",
    LBL_TODATE_VALIDATION: "Invalid To Date.",
    LBL_FROMDTCANTBEGREATERTHANTODT_VALIDATION: "From Date cannot be greater than To Date.",
    LBL_FILESHOWPOPUP_HEADERTXT: "File",
    LBL_VALIDATION_SYNCEDDOCUMENT: "Document is already synced.",
    LBL_ALL: "All",
    LBL_VIEWDOCUMENT: "View Document",
    items: [
        {
            name: "/Images/Carousel1.JPG"
        },
        {
            name: "/Images/Carousel2.JPG"
        },
        {
            name: "/Images/Carousel3.JPG"
        }],

    navigationList: [
        "home", "about", "gallery", "services", "contact Us"
    ],

    galleryItems: [
        {
            name: "/Images/IMG_1990.JPG",
            description: "Probably the most random thing you have ever seen!"
        },
        {
            name: "/Images/IMG_1991.JPG",
            description: "Hello World!"
        },
        {
            name: "/Images/IMG_1999.JPG",
            description: "Hello World!"
        },
        {
            name: "/Images/IMG_2000.JPG",
            description: "Hello World!"
        },
        {
            name: "/Images/Crop2.jpg",
            description: "Hello World!"
        }],

    galleryItemsCounter: 70,
    isDrawerOpen: false,
};

export function CarvingReducer(state = initialState, action: any) {
    switch (action.type) {
        case CARVING_SETSTATE:
            return { ...state, [action.payload.key]: action.payload.value };
        default:
            return state;
    }
}
