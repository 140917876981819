import { configureStore } from '@reduxjs/toolkit';
import { AppReducer } from '../AppReducer';
import { CarvingReducer } from '../Components/CarvingComponent/CarvingReducer';


export const store = configureStore({
  reducer: {
    app: AppReducer,
    carving: CarvingReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
