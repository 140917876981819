import { Card, CardActions, CardContent, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { ConnectedProps, connect } from "react-redux";
import { setStateVariable, updateStateVariables } from "../../AppAction";
import "./Service.css";
// import TabPanel from '@mui/lab/TabPanel';
// or

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

class ServicePage extends React.Component<Props> {

    shouldComponentUpdate() {
        return true;
    }

    render() {
        const { carving } = this.props;
        return (
            <div className="marginClass sevicePageBackgound">
                <Grid container>
                    <Grid item xs={12} className="galleryHeader" >
                        Plans & Pricing
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} >
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="accordantHeader"
                            >
                                Vegetable Carving
                            </AccordionSummary>
                            <AccordionDetails>

                                <Grid container spacing={2}>

                                    <Grid item sm={4}>
                                        <Card sx={{ minWidth: 275 }} className="servicePageGridItemClass">
                                            <CardContent>
                                                <Paper>
                                                    <img src={'/Images/ServicePage2.JPG'} className='servicePageimgClass'></img>
                                                </Paper>
                                                <Typography sx={{ mb: 1.5 }} color="text.secondary" className="cardHeader" >
                                                    Lord Ganesh
                                                </Typography>
                                                <Typography variant="body2" className="descriptionClass">
                                                    Statue of Lord Ganesh with flower containers decorated with parrots and vegetable flowers.
                                                    Placed during a marriage ceremony in a salad bar.
                                                </Typography>
                                                <Divider />
                                                <Typography variant="body2" className="servPriceClass">
                                                    <br />
                                                    Price - Rs 3000
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                            </CardActions>
                                        </Card>
                                    </Grid>

                                    <Grid item sm={4} >
                                        <Card sx={{ minWidth: 275 }} className="servicePageGridItemClass">
                                            <CardContent>
                                                <Paper >
                                                    <img src={'/Images/ServicePage1.JPG'} className='servicePageimgClass'></img>
                                                </Paper>
                                                <Typography sx={{ mb: 1.5 }} color="text.secondary" className="cardHeader" >
                                                    Ship & LightHouse
                                                </Typography>
                                                <Typography variant="body2" className="descriptionClass">
                                                    Created with pumpking, the ship`s windows were precisely curved out.
                                                    The model was created during a business function that Lightout and Lightship hosted at the Hotel HHI.
                                                </Typography>
                                                <Divider />
                                                <Typography variant="body2" className="servPriceClass">
                                                    <br />
                                                    Price - Rs 3000
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                {/* <Button size="small">Learn More</Button> */}
                                            </CardActions>
                                        </Card>
                                    </Grid>

                                    <Grid item sm={4}>
                                        <Card sx={{ minWidth: 275 }} className="servicePageGridItemClass">
                                            <CardContent>
                                                <Paper>
                                                    <img src={'/Images/ServicePage6.JPG'} className='servicePageimgClass'></img>
                                                </Paper>
                                                <Typography sx={{ mb: 1.5 }} color="text.secondary" className="cardHeader" >
                                                    Owl & Greet man
                                                </Typography>
                                                <Typography variant="body2" className="descriptionClass">
                                                    A gourd-made owl perched atop a pumking, holding its chicks. Two greet Man at the salad bar.
                                                    Looks fantastic on the entrance to a reception party.
                                                </Typography>
                                                <Divider />
                                                <Typography variant="body2" className="servPriceClass">
                                                    <br />
                                                    Price - Rs 2000
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                            </CardActions>
                                        </Card>
                                    </Grid>

                                    <Grid item sm={4}>
                                        <Card sx={{ minWidth: 275 }} className="servicePageGridItemClass">
                                            <CardContent>
                                                <Paper>
                                                    <img src={'/Images/ServicePage3.JPG'} className='servicePageimgClass'></img>
                                                </Paper>
                                                <Typography sx={{ mb: 1.5 }} color="text.secondary" className="cardHeader" >
                                                    Swaraswati with Swan
                                                </Typography>
                                                <Typography variant="body2" className="descriptionClass">
                                                    {/* A large swan is positioned next to a statue of Shri Swarawati inside a chariot.
                                                    Placed at a salad counter during a marriage ceremony on the day of Swarswati Puja. */}
                                                    Shri Swarawati in a chariot placed close to a swan.
                                                    Placed at a salad bar during on event on the day of Swarswati Puja.
                                                </Typography>
                                                <Divider />
                                                <Typography variant="body2" className="servPriceClass">
                                                    <br />
                                                    Price - Rs 3000
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                            </CardActions>
                                        </Card>
                                    </Grid>

                                    <Grid item sm={4}>
                                        <Card sx={{ minWidth: 275 }} className="servicePageGridItemClass">
                                            <CardContent>
                                                <Paper>
                                                    <img src={'/Images/ServicePage4.JPG'} className='servicePageimgClass'></img>
                                                </Paper>
                                                <Typography sx={{ mb: 1.5 }} color="text.secondary" className="cardHeader" >
                                                    Owl & Peacock
                                                </Typography>
                                                <Typography variant="body2" className="descriptionClass">
                                                    A double model with two peacocks and an owl statue. Positioned in a salad bar during a marriage ceremony.
                                                </Typography>
                                                <Divider />
                                                <Typography variant="body2" className="servPriceClass">
                                                    <br />
                                                    Price - Rs 3500
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                            </CardActions>
                                        </Card>
                                    </Grid>


                                    <Grid item sm={4}>
                                        <Card sx={{ minWidth: 275 }} className="servicePageGridItemClass">
                                            <CardContent>
                                                <Paper>
                                                    <img src={'/Images/ServicePage5.JPG'} className='servicePageimgClass'></img>
                                                </Paper>
                                                <Typography sx={{ mb: 1.5 }} color="text.secondary" className="cardHeader" >
                                                    Teddy Bears & Donuld Duck
                                                </Typography>
                                                <Typography variant="body2" className="descriptionClass">
                                                    On the occasion of the birthday celebration. Thanks to Teddy and the doll ducks, it is well favored by young kids.
                                                </Typography>
                                                <Divider />
                                                <Typography variant="body2" className="servPriceClass">
                                                    <br />
                                                    Price - Rs 2500
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item className="noteClass">
                                        <span><b>Note</b>:</span>
                                        <span> Mention prices are only applicable in Kolkata. Charges vary outside Kolkata. All materials are provided by us.</span>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    {/* Ice carving section */}
                    <Grid item xs={12} >
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="accordantHeader"
                            >
                                Ice Carving
                            </AccordionSummary>
                            <AccordionDetails>

                                <Grid container spacing={2}>
                                    <Grid item sm={4}>
                                        <Card sx={{ minWidth: 275 }} className="servicePageGridItemClass">
                                            <CardContent>
                                                <Paper>
                                                    <img src={'/Images/ServicePage7.JPG'} className='servicePageimgClass'></img>
                                                </Paper>
                                                <Typography sx={{ mb: 1.5 }} color="text.secondary" className="cardHeader" >
                                                    Eagle on a Globe
                                                </Typography>
                                                <Typography variant="body2" className="descriptionClass">
                                                    The bird appears amazing against the white ice. Placed during a corporate function hosted by Lighthouse and Lightships.
                                                </Typography>
                                                <Divider />
                                                <Typography variant="body2" className="servPriceClass">
                                                    <br />
                                                    Price - Rs 3000
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                {/* <Button size="small">Learn More</Button> */}
                                            </CardActions>
                                        </Card>
                                    </Grid>

                                    <Grid item sm={4}>
                                        <Card sx={{ minWidth: 275 }} className="servicePageGridItemClass">
                                            <CardContent>
                                                <Paper>
                                                    <img src={'/Images/ServicePage8.JPG'} className='servicePageimgClass'></img>
                                                </Paper>
                                                <Typography sx={{ mb: 1.5 }} color="text.secondary" className="cardHeader" >
                                                    Shivling
                                                </Typography>
                                                <Typography variant="body2" className="descriptionClass">
                                                    Designed for the Maha Shivratri celebration, which was arranged by a Burra bazar organization.
                                                </Typography>
                                                <Divider />
                                                <Typography variant="body2" className="servPriceClass">
                                                    <br />
                                                    Price - Rs 3000
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                            </CardActions>
                                        </Card>
                                    </Grid>

                                    <Grid item sm={4}>
                                        <Card sx={{ minWidth: 275 }} className="servicePageGridItemClass">
                                            <CardContent>
                                                <Paper>
                                                    <img src={'/Images/ServicePage9.JPG'} className='servicePageimgClass'></img>
                                                </Paper>
                                                <Typography sx={{ mb: 1.5 }} color="text.secondary" className="cardHeader" >
                                                    Lord Ganesha
                                                </Typography>
                                                <Typography variant="body2" className="descriptionClass">
                                                    Lord Ganesha statue in yellow.
                                                    Significantly superior to any typical entrance statue for any events.
                                                </Typography>
                                                <Divider />
                                                <Typography variant="body2" className="servPriceClass">
                                                    <br />
                                                    Price - Rs 3000
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                            </CardActions>
                                        </Card>
                                    </Grid>

                                    <Grid item sm={4}>
                                        <Card sx={{ minWidth: 275 }} className="servicePageGridItemClass">
                                            <CardContent>
                                                <Paper>
                                                    <img src={'/Images/ServicePage10.JPG'} className='servicePageimgClass'></img>
                                                </Paper>
                                                <Typography sx={{ mb: 1.5 }} color="text.secondary" className="cardHeader" >
                                                    Peacock
                                                </Typography>
                                                <Typography variant="body2" className="descriptionClass">
                                                    Excellent for weddings and receptions. The visitor`s eye was drawn to this ice-carved bird.
                                                </Typography>
                                                <Divider />
                                                <Typography variant="body2" className="servPriceClass">
                                                    <br />
                                                    Price - Rs 2500
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                            </CardActions>
                                        </Card>
                                    </Grid>

                                    <Grid item sm={4} >
                                        <Card sx={{ minWidth: 275 }} className="servicePageGridItemClass">
                                            <CardContent>
                                                <Paper>
                                                    <img src={'/Images/ServicePage11.JPG'} className='servicePageimgClass'></img>
                                                </Paper>
                                                <Typography sx={{ mb: 1.5 }} color="text.secondary" className="cardHeader" >
                                                    Elephant
                                                </Typography>
                                                <Typography variant="body2" className="descriptionClass">
                                                    Created as a live counter model for a marriage ceremony at The Lake Land Country Club.
                                                </Typography>
                                                <Divider />
                                                <Typography variant="body2" className="servPriceClass">
                                                    <br />
                                                    Price - Rs 3000
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item className="noteClass">
                                        <span><b>Note</b>:</span>
                                        <span> Mention cutting prices are only applicable in Kolkata. Charges vary outside Kolkata. Ice should be provided on the spot. </span>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    {/* Cartering section */}
                    <Grid item xs={12} >
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="accordantHeader"
                            >
                                Cartering service
                            </AccordionSummary>
                            <AccordionDetails>

                                <Grid container>
                                    <Grid item sm={12}>
                                        <span className="spanCartServ">Pricing is not currently available. For more details, please get in touch with us.</span>
                                    </Grid>

                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    {/* Carving learning platform*/}
                    <Grid item xs={12} >
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="accordantHeader"
                            >
                                Carving learning platform
                            </AccordionSummary>
                            <AccordionDetails>

                                <Grid container>
                                    <Grid item sm={4}></Grid>
                                    <Grid item sm={6} className="textAlignLeft">
                                        With a 40-hour course, learn the entire art of vegetable carving.
                                        <br />
                                        Four hours are allocated for each session.
                                        <br />
                                        Price - Rs 3000
                                    </Grid>
                                    <Grid item sm={2}></Grid>
                                </Grid>

                                <Grid container spacing={2}>

                                    <Grid item sm={4}>
                                        <Card sx={{ minWidth: 275 }} className="servicePageGridItemClass">
                                            <CardContent>
                                                <Paper>
                                                    <img src={'/Images/KOLKATA.PNG'} className='servicePageimgClass'></img>
                                                </Paper>
                                                <Typography sx={{ mb: 1.5 }} color="text.secondary" className="cardHeader" >
                                                    Kolkata
                                                </Typography>
                                                <Typography variant="body2" className="descriptionClass">
                                                    Four hours session every week.
                                                </Typography>
                                                {/* <Divider />
                                                <Typography variant="body2" className="servPriceClass">
                                                    <br />
                                                    Price - Rs 3000
                                                </Typography> */}
                                            </CardContent>
                                            <CardActions>
                                                {/* <Button size="small">Learn More</Button> */}
                                            </CardActions>
                                        </Card>
                                    </Grid>

                                    <Grid item sm={4}>
                                        <Card sx={{ minWidth: 275 }} className="servicePageGridItemClass">
                                            <CardContent>
                                                <Paper>
                                                    <img src={'/Images/BANGOAN.PNG'} className='servicePageimgClass'></img>
                                                </Paper>
                                                <Typography sx={{ mb: 1.5 }} color="text.secondary" className="cardHeader" >
                                                    Boangaon
                                                </Typography>
                                                <Typography variant="body2" className="descriptionClass">
                                                    Four hours session every 2nd week.
                                                </Typography>
                                                {/* <Divider />
                                                <Typography variant="body2" className="servPriceClass">
                                                    <br />
                                                    Price - Rs 3000
                                                </Typography> */}
                                            </CardContent>
                                            <CardActions>
                                                {/* <Button size="small">Learn More</Button> */}
                                            </CardActions>
                                        </Card>
                                    </Grid>

                                    <Grid item sm={4}>
                                        <Card sx={{ minWidth: 275 }} className="servicePageGridItemClass">
                                            <CardContent>
                                                <Paper>
                                                    <img src={'/Images/DURGAPUR.PNG'} className='servicePageimgClass'></img>
                                                </Paper>
                                                <Typography sx={{ mb: 1.5 }} color="text.secondary" className="cardHeader" >
                                                    Durgapur
                                                </Typography>
                                                <Typography variant="body2" className="descriptionClass">
                                                    Four hours session on the second and fourth Saturdays.
                                                </Typography>
                                                {/* <Divider />
                                                <Typography variant="body2" className="servPriceClass">
                                                    <br />
                                                    Price - Rs 3000
                                                </Typography> */}
                                            </CardContent>
                                            <CardActions>
                                                {/* <Button size="small">Learn More</Button> */}
                                            </CardActions>
                                        </Card>
                                    </Grid>

                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </div >
        )
    }
}

const mapStateToProps = (state: any) => {
    const app = state.app;
    const carving = state.carving;
    return {
        app: app,
        carving: carving,
    };
};

const mapDispatchToProps = {
    setStateVariable,
    updateStateVariables,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

export default connector(ServicePage);