import { APP_SETSTATE } from "./AppActionType";

const initialState = {
    appTitle: "Fancy Carving",
    isShowBackdrop: false,
};

export function AppReducer(state = initialState, action: any) {
    switch (action.type) {
        case APP_SETSTATE:
            return { ...state, [action.payload.key]: action.payload.value };
        default:
            return state;
    }
}
