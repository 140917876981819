import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Button, Card, CardActions, CardContent, CssBaseline, Divider, Drawer, Grid, IconButton, List, ListItem, ListItemButton, ListItemText, Paper, Toolbar, Typography, styled } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import React from "react";
import Carousel from 'react-material-ui-carousel';
import { ConnectedProps, connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';
import { setStateVariable, updateStateVariables } from "../../AppAction";
import "./Carving.css";
import { CARVING_SETSTATE } from './CarvingActionType';

// const history = createBrowserHistory();


const GridEditTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#82b6e5',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[2],
        fontSize: 11,
    },
}));

class CarvingPage extends React.Component<Props> {

    async componentDidMount() {
        const { setStateVariable, updateStateVariables } = this.props;
        // setStateVariable(APP_SETSTATE, "isShowBackdrop", true);
        setStateVariable(CARVING_SETSTATE, "isShowMenuToggle", false);
        // setStateVariable(CARVING_SETSTATE, "isShowMenuToggle", false);
    }

    shouldComponentUpdate() {
        return true;
    }

    handleDrawerToggle = () => {
        const { setStateVariable, updateStateVariables } = this.props;
        // setStateVariable(APP_SETSTATE, "isShowBackdrop", true);
        setStateVariable(CARVING_SETSTATE, "isShowMenuToggle", true);
        console.log("Test");
    }

    handleNavigation = async (item: any) => {
        // const navigate = useRouteMatch();
        const { setStateVariable, updateStateVariables } = this.props;
        console.log("item+" + item);
        // navigate.path.at("gallery");
    }

    renderCarousel = () => {
        const { carving } = this.props;
        return (
            <Carousel className="carouselMaxWidth">{
                carving.items.map((abc: any, k: any) => {
                    return (
                        <Paper key={k}>
                            <img src={abc.name} className='carouselImg'></img>
                        </Paper>)
                })
            }
            </Carousel>
        );
    }

    onClickMoreInfo = (routePage: string) => {
        const { history } = this.props;
        history.push(routePage);
    }

    render() {
        const { carving } = this.props;
        return (
            // <div className="fullWidth">
            <div className="homePageHeightClass">
                <Grid container>
                    <Grid item xs={12} >
                        Carving page
                    </Grid>
                </Grid>
                <div>
                    <this.renderCarousel />
                </div>
                <div>
                    <Grid container spacing={4} className="cardContainerClass">
                        <Grid item xs={12} sm={4}>
                            <Card sx={{ minWidth: 200 }}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} gutterBottom className="homeCardTitleClass">
                                        Services
                                    </Typography>
                                    <Typography variant="body2" className='cardDescriptionClass'>
                                        We offer a diverse selection of ice and vegetable carvings. We provide catering for every kind of gathering.
                                        {/* Also, locate appropriate resources to learn how to do vegetable carvings. */}
                                    </Typography>
                                </CardContent>
                                <CardActions className="cardButtonActionClass" >
                                    <Button size="medium" className="cardButton" onClick={() => this.onClickMoreInfo("services")}>More Info</Button>
                                </CardActions>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Card sx={{ minWidth: 200 }}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} gutterBottom className="homeCardTitleClass">
                                        Gallery
                                    </Typography>
                                    <Typography variant="body2" className='cardDescriptionClass'>
                                        For a large selection of vegetable and ice carving models, please visit our gallery section.
                                    </Typography>
                                </CardContent>
                                <CardActions className="cardButtonActionClass" >
                                    <Button size="medium" className="cardButton" onClick={() => this.onClickMoreInfo("gallery")}>More Info</Button>
                                </CardActions>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Card sx={{ minWidth: 200 }}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} gutterBottom className="homeCardTitleClass">
                                        Contact Us
                                    </Typography>
                                    <Typography variant="body2" className='cardDescriptionClass'>
                                        We are not taking online orders at this time. Please contact if you would like to place an order.
                                    </Typography>
                                </CardContent>
                                <CardActions className="cardButtonActionClass" >
                                    <Button size="medium" className="cardButton" onClick={() => this.onClickMoreInfo("contact us")}>More Info</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state: any) => {
    const app = state.app;
    const carving = state.carving;
    return {
        app: app,
        carving: carving
    };
};

const mapDispatchToProps = {
    setStateVariable,
    updateStateVariables,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    history: any;
};

export default withRouter(connector(CarvingPage));