import { Card, CardActions, CardContent, Grid, Paper, Typography, styled } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import React from "react";
import { ConnectedProps, connect } from "react-redux";
import { setStateVariable, updateStateVariables } from "../../AppAction";
import "./About.css";


class AboutPage extends React.Component<Props> {


    shouldComponentUpdate() {
        return true;
    }

    render() {
        return (
            <div className="marginClass">
                <Grid container>
                    <Grid item xs={12} className="galleryHeader" >
                        About
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <Grid container>
                            <Grid item sm={2}>

                            </Grid>
                            <Grid item sm={8} className="aboutDescMainClass">
                                For the past three decades, Fancy Carving & Catering has been offering vegetable and ice carving as well as catering services in Kolkata and various regions of West Bengal.
                                We provide exquisite models of ice and vegetable carvings that are tailored to the specific requirements of the event.
                                We offer top-notch food and service for any occasion through our catering services.
                            </Grid>
                            <Grid item sm={2}>

                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} className="galleryHeader" sm={6} >
                        <img src={'/Images/About1.JPG'} className='aboutStartingImg'></img>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} className="galleryHeader" sm={6}>
                        {/* Vegetable Carving */}
                        <Card sx={{ minWidth: 275 }} className="abountCardClass">
                            <CardContent>
                                <Paper>
                                    <img src={'/Images/About2.JPG'} className='abountImgClass'></img>
                                </Paper>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary" className="cardHeader" >
                                    Vegetable Carving
                                </Typography>
                                <Typography variant="body2" className="descriptionClass">
                                    Carvings of vegetables are typically situated next to the salad bar or at the entryway for events like as marriage-related get-togethers,
                                    dinner parties, anniversary celebrations, corporatae services, and festival celebrations.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                {/* <Button size="small">Learn More</Button> */}
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} className="galleryHeader" sm={6}>
                        {/* Ice Carving */}
                        <Card sx={{ minWidth: 275 }} className="abountCardClass">
                            <CardContent>
                                <Paper>
                                    <img src={'/Images/About3.JPG'} className='abountImgClass'></img>
                                </Paper>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary" className="cardHeader" >
                                    Ice Carving
                                </Typography>
                                <Typography variant="body2" className="descriptionClass">
                                    Many Big and Live Ice models that can be used in place of standard statues.
                                    The sculptures are even more stunning in the winter since they stay for more than 12 hours and melt evenly. In the summer,
                                    it lasts for eight hours. We also provide ice models to area clubs, hotels, and residences during festival events (like Shiv linga, Ganesh Murti, etc.).
                                </Typography>
                            </CardContent>
                            <CardActions>
                                {/* <Button size="small">Learn More</Button> */}
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} className="galleryHeader" sm={6}>
                        {/* Catering */}
                        <Card sx={{ minWidth: 275 }} className="abountCardClass">
                            <CardContent>
                                <Paper>
                                    <img src={'/Images/About4.gif'} className='abountImgClass'></img>
                                </Paper>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary" className="cardHeader" >
                                    Catering Service
                                </Typography>
                                <Typography variant="body2" className="descriptionClass">
                                    Are you trying to find the ideal meal to go with your special event? There`s nowhere else to look! A delectable menu is offered by Fancy Caterers to meet
                                    all of your catering requirements. Our comprehensive variety of indoor and outdoor catering services
                                    includes full meal preparation and presentation for formal and informal parties, regardless of the size of the event—large, medium, or small.
                                </Typography>
                            </CardContent>
                            <CardActions>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} className="galleryHeader" sm={6} >
                        {/* Carving Learning Platform */}
                        <Card sx={{ minWidth: 275 }} className="abountCardClass">
                            <CardContent>
                                <Paper className="aboutPaperImgClass">
                                    <img src={'/Images/About5.JPG'} className='abountImgClass'></img>
                                </Paper>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary" className="cardHeader" >
                                    Carving Learning Platform
                                </Typography>
                                <Typography variant="body2" className="descriptionClass">
                                    Additionally, we teach students how to carve vegetables and use various models.
                                    Right now, we only offer classes in Boangaon and Kolkata. We are now searching for other venues to teach in.
                                </Typography>
                            </CardContent>
                            <CardActions>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </div >
        )
    }
}

const mapStateToProps = (state: any) => {
    const app = state.app;
    const carving = state.carving;
    return {
        app: app,
        carving: carving,
    };
};

const mapDispatchToProps = {
    setStateVariable,
    updateStateVariables,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

export default connector(AboutPage);