import { Box, CssBaseline, Grid, styled, Paper } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import React from "react";
import { ConnectedProps, connect } from "react-redux";
import { setStateVariable, updateStateVariables } from "../../AppAction";
import "./Gallery.css";

const apiConfig = window as any;

const GridEditTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#82b6e5',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[2],
        fontSize: 11,
    },
}));



class GalleryPage extends React.Component<Props> {



    async componentDidMount() {
        const { setStateVariable, updateStateVariables } = this.props;
        // setStateVariable(APP_SETSTATE, "isShowBackdrop", true);

        //Find Images that starts with CROP
        // var data = require("../../Images/Img.json");
        // const loadData = () => JSON.parse(JSON.stringify(img));
        // const loadData = () => fetch('./GalleryImages/Img.json')
        //     .then((res) => res.json())
        //     .then((data) => {
        //         console.log('data:', data);
        //     });
        // console.log(loadData);
        // setStateVariable(APP_SETSTATE, "isShowBackdrop", true);
    }


    shouldComponentUpdate() {
        return true;
    }

    handleDrawerToggle = () => {
        console.log("Test");
    }

    galleryPage = () => {
        const { carving } = this.props;

        const listOfImg = [];

        for (let index = 1; index < carving.galleryItemsCounter; index++) {

            const gallObj = {
                // "name": "/Images/" + "Crop" + index + ".jpg"
                "name": "Crop" + index + ".jpg"
            }
            listOfImg.push(gallObj);
        }

        const iceCarvlistOfImg = [];
        for (let index = 1; index < apiConfig.ICE_CARVING_IMAGE_COUNTER; index++) {

            const gallObj = {
                "name": "IC_" + index + ".jpg"
            }
            iceCarvlistOfImg.push(gallObj);
        }

        const counterVeg = apiConfig.VEG_CARVING_IMAGE_COUNTER;
        const vegCarvlistOfImg = [];
        // for (let index = 1; index < carving.galleryItemsCounter; index++) {
        for (let index = 1; index < counterVeg; index++) {

            const gallObj = {
                // "name": "/Images/" + "Crop" + index + ".jpg"
                "name": "VC_" + index + ".jpg"
            }
            vegCarvlistOfImg.push(gallObj);
        }
        // const container = window !== undefined ? () => document.body : undefined;
        return (
            <div >
                {/* <Grid container>
                    <Grid item xs={12} className="galleryHeader" >
                        GALLERY
                    </Grid>
                </Grid> */}
                {/* 
                <Grid container>{
                    
                    carving.galleryItems.map((abc: any, k: any) => {
                        return (
                            <Grid key={k} xs={12} sm={3} gap={10}>
                                <img src={abc.name} className='carouselImg'></img>
                            </Grid>)
                    })}                    
                </Grid> */}

                {/* <fieldset>
                    <legend> Ice Carvings</legend>
                    <Grid container spacing={2}>
                        {
                            listOfImg.map((abc: any, k: any) => {
                                return (
                                    <Grid key={k} xs={12} sm={3} item>
                                        <Paper key={k}>
                                            <img src={abc.name} className='carouselImg'></img>
                                        </Paper>
                                    </Grid>)
                            })}
                    </Grid>

                </fieldset> */}

                <fieldset>
                    <legend className="legendClass"> Ice Carvings</legend>
                    <Grid container spacing={2} className="carvingGridContainerClass">
                        {
                            iceCarvlistOfImg.map((abc: any, k: any) => {
                                return (
                                    <Grid key={k} xs={12} sm={3} item>
                                        <Paper key={k} className="imgPaperClass">
                                            {/* <img src={abc.name} className='carouselImg'></img> */}

                                            <img src={process.env.PUBLIC_URL +
                                                "/CarvingImages/IceCarving/" + abc.name} className='galleryImgClass'></img>
                                        </Paper>
                                    </Grid>)
                            })}
                    </Grid>

                </fieldset>

                <fieldset>
                    <legend className="legendClass"> Veg Carvings</legend>
                    <Grid container spacing={2}  className="carvingGridContainerClass">
                        {
                            vegCarvlistOfImg.map((abc: any, k: any) => {
                                return (
                                    <Grid key={k} xs={12} sm={3} item>
                                        <Paper key={k} className="imgPaperClass">
                                            <img src={process.env.PUBLIC_URL +
                                                "/CarvingImages/VegCarvings/" + abc.name} className='galleryImgClass'></img>
                                        </Paper>
                                    </Grid>)
                            })}
                    </Grid>

                </fieldset>




            </div>
        );
    }


    render() {
        const { carving } = this.props;
        return (
            <div className="fullWidth">
                <Grid container>
                    <Grid item xs={12} className="galleryHeader" >
                        Gallery
                    </Grid>
                </Grid>
                <div>
                    <this.galleryPage />
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state: any) => {
    const app = state.app;
    const carving = state.carving;
    return {
        app: app,
        carving: carving,
    };
};

const mapDispatchToProps = {
    setStateVariable,
    updateStateVariables,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

export default connector(GalleryPage);