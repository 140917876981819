import { Box, CssBaseline, Grid, styled, Paper, Card, CardActions, CardContent, Typography } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import React from "react";
import { ConnectedProps, connect } from "react-redux";
import { setStateVariable, updateStateVariables } from "../../AppAction";
import "./Contact.css";

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AlternateEmailSharpIcon from '@mui/icons-material/AlternateEmailSharp';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import EmailIcon from '@mui/icons-material/Email';

// import background from "../../";


const GridEditTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#82b6e5',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[2],
        fontSize: 11,
    },
}));



class ContactPage extends React.Component<Props> {



    async componentDidMount() {
        const { setStateVariable, updateStateVariables } = this.props;
        // setStateVariable(APP_SETSTATE, "isShowBackdrop", true);

    }


    shouldComponentUpdate() {
        return true;
    }

    render() {
        const { carving } = this.props;
        const bck = process.env.PUBLIC_URL + "/Images/ContactUs.jpg"
        return (
            <div className="marginClass bgImgClass">
                <Grid container>
                    <Grid item sm={2}></Grid>
                    <Grid item sm={8}>
                        <Grid container>
                            <Grid item sm={3} xs={6}>
                                <WhatsAppIcon className="iconSizeClass"></WhatsAppIcon>
                            </Grid>

                            <Grid item sm={3} xs={6}>
                                <AlternateEmailSharpIcon className="iconSizeClass"></AlternateEmailSharpIcon>
                            </Grid>
                            <Grid item sm={3} xs={6}>
                                <PhoneInTalkIcon className="iconSizeClass"></PhoneInTalkIcon>
                            </Grid>

                            <Grid item sm={3} xs={6}>
                                <EmailIcon className="iconSizeClass"></EmailIcon>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={2}></Grid>
                </Grid>


                <Grid container className="backgroundColorClass">
                    {/* <Grid item xs={12} className="colorWhiteClass contactNameClass"> */}
                    <Grid item xs={12} className="colorWhiteClass contactNameClass">
                        <h1 className="h1ClassContactus">
                            {/* CONTACT US */}
                            Contact Us
                        </h1>
                    </Grid>
                </Grid>

                <Grid container className="backgroundColorClass">
                    {/* <Grid item xs={12} className="contDesc"> */}
                    <Grid item xs={12} className="colorWhiteClass contactTextSize">
                        We are not currently taking orders online, <br />
                        however you can contact us with any questions or <br />
                        requests using the information below.
                    </Grid>
                </Grid>

                <Grid container className="colorWhiteClass backgroundColorClass">

                    {/* <Grid item xs={12} className="galleryHeader" sm={4} > */}
                    <Grid item xs={12} sm={4} >
                        <Typography className="gridTitleClass">
                            Phone
                        </Typography>
                        <Typography variant="body2" className='gridDescriptionClass'>
                            +91 8481000991 <br />
                            +91 9432326170 <br />
                        </Typography>
                    </Grid>

                    {/* <Grid item xs={12} className="galleryHeader" sm={4}> */}
                    <Grid item xs={12} sm={4}>


                        <Typography gutterBottom className="gridTitleClass">
                            Office
                        </Typography>
                        <Typography variant="body2" className='gridDescriptionClass'>
                            13 A Sagar Manna Road. <br />
                            Parnashree, Behala <br />
                            Kolkata- 700060 <br />
                            West Bengal
                        </Typography>

                    </Grid>


                    {/* <Grid item xs={12} className="galleryHeader" sm={4} > */}
                    <Grid item xs={12} sm={4} >
                        <Typography gutterBottom className="gridTitleClass">
                            Email
                        </Typography>
                        <Typography variant="body2" className='gridDescriptionClass'>
                            fancy-carving@gmail.com <br />
                        </Typography>
                    </Grid>
                </Grid>
            </div >
        )
    }
}

const mapStateToProps = (state: any) => {
    const app = state.app;
    const carving = state.carving;
    return {
        app: app,
        carving: carving,
    };
};

const mapDispatchToProps = {
    setStateVariable,
    updateStateVariables,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

export default connector(ContactPage);